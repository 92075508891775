<template>
  <VLink @click="toBack">Назад</VLink>

  <h3 class="block__subtitle mt-60 mb-40">{{ title }}</h3>

  <div class="accreditation__inner">
    <VSecurityMainInfo :main-info="main"/>
    <VSecurityContactInfo :contact-info="contact" />
    <AccompanyingDocuments :files-list="filesList" />

    <div class="accreditation__buttons mt-10">
      <button class="btn btn-primary btn-bg-green" @click="openModal('success')">Аккредитовать</button>
      <button class="btn btn-primary btn-bg-transparent" @click="openModal('fail')">Отказать</button>
    </div>

    <teleport to="body">
      <VModal
        v-if="isModalSuccess"
        @close="handlerAccept"
        confirm-question="Аккредитовать этого пользователя?"
        :confirm="true"
      />
      <VModal
        v-if="isModalFail"
        @close="closeModal('fail')"
        :confirm="false"
        confirm-question="Причина отказа"
      >
        <form @submit.prevent="onSubmit" class="form width-100">
          <div class="form__inner">

            <VSelect
              :options="selectOptions"
              name="select"
              placeholder="Причина"
              class-form="form__control__modal"
              v-model="formData.reason"
              :errors="v$.reason.$errors"
              :server-errors="serverErrors.value?.reason"
              @blur="validateField"
              @input="validateField"
            ></VSelect>

            <VTextarea
              v-model="formData.comment"
              name="modal-accreditation"
              id="textarea"
              label="Комментарий"
              text-area-class="textarea-bg-transparent mb-40"
            />

            <div class="form__buttons">
              <button type="submit" class="btn btn-primary btn-bg-green">Сохранить</button>
              <button type="button" class="btn btn-primary btn-bg-transparent" @click="closeModal('fail')">Отмена</button>
            </div>
          </div>
        </form>
      </VModal>
    </teleport>
  </div>
</template>

<script>
import VLink from '@/components/ui/buttons/VLink'
import { useRouter } from 'vue-router'
import { onBeforeMount, reactive, ref, toRefs } from 'vue'
import VSecurityContactInfo from '@/components/views/Security/VSecurityContactInfo'
import VSecurityMainInfo from '@/components/views/Security/VSecurityMainInfo'
import AccompanyingDocuments from '@/components/views/Security/VSecurityAccompanyingDocuments'
import VModal from '@/components/ui/modal/VModal'
import VSelect from '@/components/ui/form/VSelect'
import VTextarea from '@/components/ui/form/VTextarea'
import { useDeclineAccreditation } from '@/use/Form/Accreditation/useDeclineAccreditation'
import { useStore } from 'vuex'
import { changeAccreditation, getOneAccreditation } from '@/http/accreditationApi'
import { getUserFilesTypeList } from '@/utils/user/userFilesTypeAccreditation'

export default {
  setup () {
    const router = useRouter()
    const store = useStore()
    const accreditation = reactive({
      title: '',
      main: {
        organizationName: '',
        idTaxpayer: '',
        numEntity: '',
        codeActions: '',
        address: ''
      },
      contact: {
        fio: '',
        email: '',
        phone: ''
      },
      filesList: []
    })
    const isModalSuccess = ref(false)
    const isModalFail = ref(false)
    const selectOptions = reactive([
      {
        name: 'Некорректные данные',
        id: 1
      },
      {
        name: 'Без объяснения причины',
        id: 2
      },
      {
        name: 'Не соответствует требования площадки',
        id: 3
      }
    ])

    onBeforeMount(async () => {
      if (!router.currentRoute.value.params.id) return router.push({ name: 'security-accreditation-active' })
      try {
        const data = await getOneAccreditation(router.currentRoute.value.params.id)
        const elem = data.data.user
        accreditation.title = elem.organization_name
        accreditation.main = {
          organizationName: elem.organization_name,
          idTaxpayer: elem.inn,
          numEntity: elem.msrn,
          codeActions: elem.arctea,
          address: elem.organization_legal_address
        }
        accreditation.contact = {
          fio: elem.name,
          idTaxpayer: elem.inn,
          email: elem.email,
          phone: elem.phone,
          address: elem.organization_legal_address
        }

        // подготовка списка файлов по типам
        const isLegalEntity = elem.partner_type_id === 2
        const filesTypeList = getUserFilesTypeList(isLegalEntity)
          .map(item => {
            if (elem[item.type]?.length) {
              return {
                ...item,
                files: elem[item.type]
              }
            }
            return item
          })
          .filter(item => item.files)

        if (elem.file?.length) {
          filesTypeList.push({
            type: 'files',
            text: 'Прочие документы',
            files: elem.file
          })
        }

        accreditation.filesList = filesTypeList
      } catch (e) {
        await store.dispatch('setAlert', {
          type: 'error',
          description: e.response.data.message
        })
      }
    })

    const toBack = () => {
      router.push({ name: 'security-accreditation-active' })
    }

    const openModal = (name) => {
      if (name === 'fail') {
        isModalFail.value = true
      } else {
        isModalSuccess.value = true
      }
      document.body.classList.add('no-scroll')
    }

    const closeModal = (name) => {
      if (name === 'fail') {
        isModalFail.value = false
      } else {
        isModalSuccess.value = false
      }
      document.body.classList.remove('no-scroll')
    }

    const handlerAccept = async (boolean) => {
      closeModal('success')
      if (boolean) {
        try {
          await changeAccreditation(router.currentRoute.value.params.id, true)
          await router.push({ name: 'security-accreditation-active' })
          await store.dispatch('setAlert', {
            type: 'success',
            description: 'Данные успешно обновлены'
          })
        } catch (e) {
          await store.dispatch('setAlert', {
            type: 'error',
            description: 'Не удалось обновить данные'
          })
        }
      }
    }
    return {
      toBack,
      ...toRefs(accreditation),
      ...useDeclineAccreditation(router.currentRoute.value.params.id),
      isModalFail,
      isModalSuccess,
      openModal,
      selectOptions,
      closeModal,
      handlerAccept
    }
  },
  components: {
    VTextarea,
    VSelect,
    VModal,
    AccompanyingDocuments,
    VSecurityMainInfo,
    VSecurityContactInfo,
    VLink
  }
}
</script>
