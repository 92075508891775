import { reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { requiredValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { cancelAccreditation } from '@/http/accreditationApi'
import { Alert } from '@/extension/Alert/Alert'

export function useDeclineAccreditation (id) {
  const formData = reactive({
    reason: '',
    comment: ''
  })

  const rules = {
    reason: requiredValid
  }

  const formAction = async () => {
    await cancelAccreditation(id, { status: false, message: formData.comment })
    await form.router.push({ name: 'security-accreditation-active' })
    await Alert.show('success', 'Данные заявки успешно обновлены')
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
