<template>
  <h4 :class="['block__subtitle-small', filesList.length ? 'mb-40' : '']">Сопроводительная документация</h4>

  <div class="accreditation__file-list">
    <div
      class="box"
      v-for="(item, i) of filesList"
      :key="i"
    >
      <h4 class="file__title h4 mb-20">
        {{ item.text }}
      </h4>

      <VFileList
        :files="item.files"
        grid
        is-download
      />
    </div>
  </div>

<!--  TODO remove -->
<!--  <div class="accreditation__info">-->
<!--    <div class="accreditation__col">-->
<!--      <div class="accreditation__file mb-30" >-->

<!--        <VFileList-->
<!--          :files="getFiles"-->
<!--          is-download-->
<!--          grid-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script setup>
import { defineComponent, defineProps } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
// import { useStore } from 'vuex'
import VFileList from '@/components/list/FileList/VFileList'

defineProps({
  filesList: {
    type: Array,
    required: true
  }
})

// TODO remove
// const fileList = ref([])
// const store = useStore()
//
// onMounted(() => {
//   fileList.value = store.getters['auth/getUser'].file
// })
//
// const getFiles = computed(() => {
//   return fileList.value
// })

defineComponent({
  VIcon,
  VFileList
})
</script>
